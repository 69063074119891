.link {
    text-decoration: none;
    font-size: large;
     margin-left: 20px;  
     
}
.button{
    background: linear-gradient(to right,red,yellow);
}
.Footer-icon{
    font-size: 30px;
    
}
.courseBox-container{
    border: 1px solid black;
   
    padding: 0px 20px;
    color: white;
    text-align: center;
    border-radius: 10px;
    background: linear-gradient(to right,#8e0e00,#ff0013);
}
.single-box{
    margin-top: 10px
}
.course-banner{
    border-radius: 10px;
    height: 70%;
  
}
.course-description{
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
}
.about-course{
    font-size: 26px;
    font-weight: 600;
}